/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: "Myriad Pro Regular"; }
* {
    font-family: "Myriad Pro Regular", sans-serif; 
}
.cdk-overlay-container {
    z-index: 500 !important;
}
select {
    appearance: none;
}

select:focus {
    outline: none;
}
@font-face {
    font-family: 'Myriad Pro Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Myriad Pro Regular'), url('./assets/fonts/myriadpro/MYRIADPRO-REGULAR.woff') format('woff');
}

.CnS-btn {
    color: white !important;
    background-color: #c7d41f !important;
    border-color: #c7d41f !important;
}

    .CnS-btn:hover {
        background-color: #afbc1f !important;
        border-color: #afbc1f !important;
    }

    .CnS-btn:focus, .CnS-btn.focus {
        background-color: #afbc1f !important;
        box-shadow: 0 0 0 0.2rem #afbc1f !important;
    }

    .CnS-btn.disabled {
        opacity: 0.60;
    }